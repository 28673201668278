<template>
  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} article
        compositions</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} article compositions that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_CREATE)"
        to="/article-compositions/new"
        data-test="btn-new-composition"
        class="btn btn-primary ml-auto">
        New composition
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <dimmer :active="listLoading">
          <div class="card">
            <div class="table-responsive">
              <table data-test="table-article-composition" class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_EXPORT)" align="right">
                    <th colspan="6">
                      <button
                        data-test="btn-export"
                        class="btn btn-primary"
                        @click.prevent="exportCompositions">
                        <i class="fe fe-download-cloud"/> Export
                      </button>
                    </th>
                  </tr>
                  <tr>
                    <th class="w-1"></th>
                    <th>Name</th>
                    <th class="w-1"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-center">
                      <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                    </td>
                    <td>
                      <div>
                        <router-link
                          v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_VIEW)"
                          :to="`/article-compositions/${item.id}`">
                          {{item.name}}
                        </router-link>
                        <span v-else>{{item.name}}</span>
                      </div>
                    </td>
                    <td class="text-center w-1">
                      <div
                        v-if="can([uiPermissions.ARTICLES_COMPOSITIONS_VIEW, uiPermissions.ARTICLES_COMPOSITIONS_DELETE])"
                        class="item-action dropdown">
                        <a
                          tabindex="0"
                          data-toggle="dropdown"
                          data-test="link-dropdown"
                          class="icon"><i class="fe fe-more-vertical"></i></a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <router-link
                            v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_VIEW)"
                            :to="`/article-compositions/${item.id}`"
                            data-test="link-edit"
                            class="dropdown-item">
                            <i class="dropdown-icon fe fe-edit"></i> Edit
                          </router-link>
                          <template v-if="can(uiPermissions.ARTICLES_COMPOSITIONS_DELETE)">
                            <button
                              type="button"
                              data-test="btn-delete"
                              class="dropdown-item"
                              @click="handleDelete(item)">
                              <i class="dropdown-icon fe fe-trash text-danger"></i> Delete
                            </button>
                          </template>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="6">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :currentPage="page"
              :totalPages="meta.totalPages"
              @goToPage="goToPage"></pagination>
          </div>
        </dimmer>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import download from '@/mixins/download';
import {articleCompositions} from '@/services';

export default {
  name: 'ArticleCompositionIndex',
  components: {
    Pagination,
  },
  mixins: [
    list,
    download,
  ],
  data() {
    return {
      feature: true,
    };
  },
  methods: {
    fetchData(page) {
      return articleCompositions.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting article composition cannot be undone. Are you sure that you want to delete this article composition?')) {
        await articleCompositions.deleteById(item.id);
        this.refresh();
      }
    },
    async exportCompositions() {
      this.listLoading = true;

      try {
        this.download(await articleCompositions.export());
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.listLoading = false;
      }
    },
  },
};

</script>
